@import '../../node_modules/bootstrap/scss/bootstrap';

@import './fonts';

html, body {
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
}

html, body, #root {
  min-height: 100vh;
}

body {
  background-image: url(../images/bg.jpg);
  background-size: cover;
  background-position: center;
}
